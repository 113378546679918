<template>
	<div class="flex">
		<div class="w-1/2 pr-2">
			<b-card>
				<h4 class="mb-3 searchCardTitle">1. 상품그룹검색</h4>
				<div class="flex">
					<v-select class="w-1/3" v-model="opt.searchKeyName" :clearable="false" :options="searchKeyOpts" />
					<b-form-input
						class="w-1/2 ml-4"
						v-model.trim="opt.searchKeyword"
						placeholder="검색어"
						@keyup.enter="search"
					/>
					<b-button class="w-1/6 ml-4" @click="search">검색</b-button>
				</div>
				<h6 class="mt-2">※ 미입력 시 전체 상품그룹 노출</h6>

				<tb
					:inf="productGroupInf"
					:res="productGroupRes"
					cName="productGroupTable"
					checkBoxKey="productGroupIdx"
					limit="20"
				/>
			</b-card>
		</div>
		<div class="w-1/2 pl-2">
			<b-card>
				<h4 class="mb-3 searchCardTitle">2. 등록 계정 선택</h4>
				<tb :inf="hottistInf" :res="hottistRes" cName="hottistTable" checkBoxKey="seq" limit="20" nav="none" />

				<h4 class="mt-8 mb-3" v-text="'3. 기간 설정'" />
				<div class="flex">
					<div class="w-1/2 pr-2">
						<h6>딜 시작일</h6>
						<datePicker model="startDate" />
					</div>
					<div class="w-1/2 pl-2">
						<h6>딜 종료일</h6>
						<datePicker model="endDate" />
					</div>
				</div>

				<div class="flex mt-2">
					광고지원 여부
					<b-form-checkbox class="ml-2" v-model="isSupportAdvertisement" switch />
				</div>

				<b-button class="w-1/1 mt-2" @click="addManualDeal(false)" v-text="'바로 등록하기'" />
			</b-card>
		</div>
	</div>
</template>

<script>
const searchKeyOpts = [
	{ label: '상품그룹명', value: 'productGroupName' },
	{ label: 'idx', value: 'productGroupIdx' },
	{ label: '브랜드명', value: 'brandName' },
	{ label: '노출등급', value: 'productGroupClass' },
]

export default {
	data() {
		return {
			isSend: false,
			searchKeyOpts,
			opt: {
				searchKeyName: searchKeyOpts[0],
				searchKeyword: '',
			},
			productGroupInf: [
				{ model: 'checkAll' },
				{ title: 'idx', key: 'productGroupIdx', size: 2 },
				{ title: '브랜드명', key: 'brandName', size: 5 },
				{ title: '상품그룹명', key: 'productGroupName', size: 10 },
				{ title: '노출 등급', key: 'productGroupClass', size: 2 },
				{ title: '타입', key: 'productType', size: 3 },
				{ title: '앵콜 필수', key: 'essentialStatus', size: 2 },
				{ title: '등록일', key: 'enrollDate', size: 4 },
			],
			productGroupRes: [],

			hottistInf: [
				{ model: 'checkAll' },
				{ title: '인스타아이디', key: 'currentInstaId', size: 6 },
				{ title: '이름', key: 'name', size: 5 },
				{ title: '휴대폰 번호', key: 'phone', size: 6 },
				{ title: '핫트아이디', key: 'hottId', size: 6 },
				{ title: '가입일', key: 'registerTime', size: 8 },
			],
			hottistRes: [],

			startDate: new Date(),
			endDate: new Date(2099, 11, 31),

			isSupportAdvertisement: true,

			baseData: {},
			data: [],
			e: [],
		}
	},
	methods: {
		search(operation = 'LIKE') {
			if (typeof operation != 'string') operation = 'LIKE'
			const opt = this.opt,
				searchKeyName = getDropdownValue(opt.searchKeyName),
				searchVal = this.opt.searchKeyword

			let data = {
				paging: getPaging(0, 20),
				orderBy: getOrder('productGroupIdx'),
			}
			//선택 조건에 없는 필수값들 입력

			if (searchKeyName && searchVal) data.searchKey = getSearchKey(searchKeyName, searchVal, operation, 'and')

			this.lastOpt = data
			return this.changePage(1)
		},
		changePage(page = 1) {
			let data = this.lastOpt
			data.paging.pageNo = page - 1
			return postApi('/productGroupSelect/productGroupList', data).then(res => {
				res.list = res.list.map(v => {
					v.enrollDate = v.enrollDate.substr(0, 10)
					v.productType = this.getProductType(v.productType)
					v.child = false
					if (v.productGroupClass == 'z') v.trClass = 'text-gray-light'
					v.essentialStatus = v.isEssential ? 'O' : 'X'
					return v
				})
				this.productGroupRes = res
			})
		},

		getProductType(productType = 1) {
			if (!isNaN(productType)) productType *= 1
			switch (productType) {
				case 1:
				default:
					return '일반'
				case '일반':
					return 1
				case 2:
					return '해외직구'
				case '해외직구':
					return 2
				case 3:
					return 'BS렌탈'
				case 'BS렌탈':
					return 3
				case 4:
					return '렌탈+일반'
				case '렌탈+일반':
					return 4
				case 5:
					return '주류'
				case '주류':
					return 5
			}
		},
		getProductOptionType(productOptionType = 1) {
			if (!isNaN(productOptionType)) productOptionType *= 1
			switch (productOptionType) {
				case 1:
				default:
					return '일반'
				case '일반':
					return 1
				case 2:
					return '렌탈(배송)'
				case '렌탈(배송)':
					return 2
				case 3:
					return '렌탈(설치)'
				case '렌탈(설치)':
					return 3
			}
		},
		addManualDeal(isTemporary = false) {
			//유효성 검사
			const hottist = this.hottistTable.getChecked(),
				productGroup = this.productGroupTable.getChecked(),
				e = []

			if (!hottist.length) e.push('핫티스트를 선택해주세요')

			if (!productGroup.length) e.push('상품 그룹을 선택해주세요')

			if (this.startDate && this.endDate) {
				if (this.startDate > this.endDate) e.push('시작일이 종료일보다 늦습니다')
			} else e.push('시작일 또는 종료일을 선택해주세요')

			if (e.length) return alert.w(e)
			if (this.isSend) return
			this.isSend = true
			this.data = []
			this.e = ['일부 딜 생성 중 오류가 발생했습니다']
			const startDate = getDateValue(this.startDate),
				endDate = getDateValue(this.endDate, false)
			this.baseData = {
				memberSeq: '',
				startDate,
				endDate,
				isAutoEncore: false,
				productGroupIdx: '',
				productOptionSeq: 'empty',
				isComplete: 'O',
				adminSeq: layout.user.seqNo,
				chargeAdd: 0,
				isSupportAdvertisement: this.isSupportAdvertisement,
			}

			hottist.map(h => {
				productGroup.map(p => {
					this.data.push({
						memberSeq: h.seq,
						memberName: h.name,
						hottId: h.hottId,
						productGroupIdx: p.productGroupIdx,
						productGroupName: p.productGroupName,
					})
				})
			})
			this.syncInsert()
		},

		syncInsert() {
			if (this.data.length) {
				const data = {
					...this.baseData,
					...this.data.pop(),
				}
				postApi('/dealHandle/insertManualDeal', data, false, false).then(res => {
					if (res.success != '0000')
						this.e.push(`${data.hottId}(${data.memberName}), ${data.productGroupIdx}: ${res.message}`)
					this.syncInsert()
				})
			} else {
				if (this.e.length > 1) alert.w(this.e)
				else alert.s('선택한 계정에 딜 생성이 완료되었습니다')
				this.hottistTable.clearChecked()
				this.productGroupTable.clearChecked()
				this.isSend = false
			}
		},
	},
	computed: {},
	created() {
		postApi('/hottistSelect/hottistList', {
			saveStatus: 'Y',
			userType: 'A',
			isExcel: 0,
			paging: getPaging(),
			orderBy: getOrder('registerTime'),
			searchKey: ['aaaaa', 'hott_animal', 'ss_beans'].map(v => {
				return {
					fieldName: 'hottId',
					value: v,
					andOr: 'or',
					operation: 'EQUAL',
				}
			}),
		}).then(res => {
			res.list = res.list.map(v => {
				v.registerTime = v.registerTime.substr(0, 10)
				return v
			})
			this.hottistRes = res
		})
	},
}
</script>
